//#region Imports
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../../../environments/environment';
//#endregion
//#region Sentry
Sentry.init({
    dsn: environment.sentry.dns,
    environment: environment.sentry.environment,
    release: '1.0.0',
});
Sentry.setTags(environment.sentry.tags);
//#endregion
/**
 * A classe que representa o serviço que lida com a entrega de erros para a Sentry
 */
var SentryErrorHandler = /** @class */ (function () {
    function SentryErrorHandler() {
    }
    /**
     * Método que é invocado para lidar com exceções lançadas pela aplicação
     *
     * @param error As informações do erro lançado
     */
    SentryErrorHandler.prototype.handleError = function (error) {
        var eventId = Sentry.captureException(error.originalError || error);
        Sentry.showReportDialog(__assign({ eventId: eventId }, environment.sentry.feedback));
    };
    return SentryErrorHandler;
}());
export { SentryErrorHandler };
//#endregion
