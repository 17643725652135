<div class="logo">
    <a class="simple-text app-name" href="#">
        <div class="logo-img">
            <img [src]="icon_name"/>
        </div>
        {{ appName }}
    </a>
</div>
<div class="sidebar-wrapper">
    <ul class="nav">
        <li *ngFor="let menuItem of menuItems" class="{{ menuItem.class }} nav-item" routerLinkActive="active">
            <a [routerLink]="[menuItem.path]" class="nav-link">
                <i class="material-icons">{{ menuItem.icon }}</i>
                <p>{{ menuItem.title }}</p>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link clickable" (click)="onClickSupport()">
                <i class="material-icons">bug_report</i>
                <p>Suporte</p>
            </a>
        </li>
    </ul>
</div>
