var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import { environment } from '../../../environments/environment';
import { Keys } from '../../utils/keys';
//#endregion
//#region Components
var SidebarComponent = /** @class */ (function () {
    //#region Constructor
    /**
     * Construtor padrão
     */
    function SidebarComponent() {
        //#endregion
        //#region Public Properties
        /**
         * Os itens do menu
         */
        this.menuItems = Keys.ROUTES;
        /**
         * O nome da aplicação
         */
        this.appName = environment.app_name;
        /**
         * O icone da aplicação
         */
        this.icon_name = environment.icon_name;
    }
    //#endregion
    //#region Public Methods
    /**
     * Método que diz se a resolução da tela é mobile
     */
    SidebarComponent.prototype.isMobileMenu = function () {
        return !($(window).width() > 991);
    };
    /**
     * Método que abre a modal de suporte
     */
    SidebarComponent.prototype.onClickSupport = function () {
        var eventId = Sentry.captureMessage('Suporte', Severity.Info);
        Sentry.showReportDialog(__assign({ eventId: eventId }, environment.sentry.feedback));
    };
    return SidebarComponent;
}());
export { SidebarComponent };
