export const environment = {
  production: true,
  app_name: 'Nor Import',
  icon_name: './assets/img/nor.jpg',
  api_endpoint: 'https://api.norimport.com.br/prod',
  sentry: {
    dns: 'https://708a11ee0620411cb1aa9ca11199f309@sentry.viniciusl.com.br/4',
    environment: 'production',
    tags: {
      framework: 'angular',
      provider: 'norimport',
      type: 'dashboard',
    },
    feedback: {
      lang: 'pt-BR',
      title: 'Parece que estamos tendo alguns problemas.',
      subtitle: 'Nossa equipe foi notificada.',
      subtitle2: 'Se você quiser ajudar, conte-nos o que aconteceu abaixo.',
      labelName: 'Nome',
      labelEmail: 'E-mail',
      labelComments: 'O que aconteceu?',
      labelSubmit: 'Enviar',
      labelClose: 'Fechar',
      errorGeneric: 'Ocorreu um erro desconhecido ao enviar o seu feedback. Por favor, tente novamente.',
      errorFormEntry: 'Alguns campos são inválidos. Corrija os erros e tente novamente.',
      successMessage: 'Seu feedback foi enviado. Obrigado!',
    },
  },
};
