//#region Imports

import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

import { environment } from '../../../environments/environment';

//#endregion

//#region Sentry

Sentry.init({
  dsn: environment.sentry.dns,
  environment: environment.sentry.environment,
  release: '1.0.0',
});

Sentry.setTags(environment.sentry.tags);

//#endregion

/**
 * A classe que representa o serviço que lida com a entrega de erros para a Sentry
 */
@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  /**
   * Método que é invocado para lidar com exceções lançadas pela aplicação
   *
   * @param error As informações do erro lançado
   */
  public handleError(error): void {
    const eventId = Sentry.captureException(error.originalError || error);

    Sentry.showReportDialog({
      eventId,
      ...environment.sentry.feedback,
    });
  }
}

//#endregion
