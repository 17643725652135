import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/pt-PT';
import { httpAsyncFactory } from './factories/http-async/http-async.factory';
registerLocaleData(localeFr, 'pt-PT');
var ɵ0 = httpAsyncFactory;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
