import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import decode from 'jwt-decode';
import { JqueryHelper } from '../../utils/jquery';
import { Keys } from '../../utils/keys';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var AuthGuard = /** @class */ (function () {
    //#region Constructor
    /**
     * Construtor padrão
     */
    function AuthGuard(router) {
        this.router = router;
    }
    //#endregion
    //#region Public Methods
    /**
     * Método que diz se pode ativar a rota
     */
    AuthGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        var token = localStorage.getItem(Keys.TOKEN);
        if (token && next.data.redirectIfLogged) {
            this.router.navigate(['dashboard', 'emails']);
            return true;
        }
        var notHavePermissions = function () {
            JqueryHelper.error('Você não possui permissões para acessar essa página.');
            _this.router.navigate(['login']);
            localStorage.removeItem(Keys.TOKEN);
            return false;
        };
        var jwt = new JwtHelperService();
        if (!token || jwt.isTokenExpired(token))
            return notHavePermissions();
        if (next.data.expectedRole) {
            var tokenPayload = decode(token);
            if (typeof tokenPayload.roles !== 'string')
                return notHavePermissions();
            if (typeof next.data.expectedRole !== 'string')
                return notHavePermissions();
            if (!tokenPayload.roles.split(',').some(function (role) { return next.data.expectedRole.includes(role); }))
                return notHavePermissions();
        }
        return true;
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
