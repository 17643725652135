var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
//#region Imports
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
//#endregion
//#region Class
/**
 * A classe que representa um serviço responsável por lidar com as chamadas assincronas em um Endpoint
 */
var HttpAsyncService = /** @class */ (function () {
    //#region Construtor
    /**
     * Construtor padrão
     *
     * @param http Modulo HTTP
     */
    function HttpAsyncService(http) {
        this.http = http;
        //#endregion
        //#region Private Properties
        /**
         * Url base para realizar as chamadas
         */
        this.baseUrl = environment.api_endpoint;
        this.onAsyncResultError = new Subject();
    }
    //#endregion
    //#region Public Methods
    /**
     * Método que seta uma validação a ser executado antes de cada requisição
     */
    HttpAsyncService.prototype.setBeforeValidations = function (beforeValidation) {
        this.beforeValidations = beforeValidation;
    };
    /**
     * Método que seta uma validação a ser executado antes de cada requisição
     */
    HttpAsyncService.prototype.setLoadHeaders = function (loadHeader) {
        this.loadHeaders = loadHeader;
    };
    /**
     * Método que retorna o evento chamado ao ocorrer um erro com a chamada API
     */
    HttpAsyncService.prototype.getOnAsyncResultError = function () {
        return this.onAsyncResultError;
    };
    /**
     * Método que define uma nova base de url para as chamadas
     *
     * @param newBaseUrl O novo url
     */
    HttpAsyncService.prototype.setBaseUrl = function (newBaseUrl) {
        this.baseUrl = newBaseUrl;
    };
    //#endregion
    //#region Private Methods
    /**
     * Converte um resultado para AsyncResult para quando der certo
     *
     * @param result O resultado obtido
     */
    HttpAsyncService.prototype.success = function (result) {
        return {
            success: result
        };
    };
    /**
     * Encapsula o erro no AsyncResult
     *
     * @param error O erro enviado pelo servidor
     */
    HttpAsyncService.prototype.error = function (error) {
        this.onAsyncResultError.next(error);
        return {
            error: error
        };
    };
    /**
     * Método que obtém os headers
     */
    HttpAsyncService.prototype.getHeaders = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.loadHeaders === undefined)
                            return [2 /*return*/, undefined];
                        return [4 /*yield*/, this.loadHeaders()];
                    case 1:
                        result = _a.sent();
                        if (result === undefined)
                            return [2 /*return*/, undefined];
                        return [2 /*return*/, { headers: result }];
                }
            });
        });
    };
    //#endregion
    //#region Async Restfull Methods
    /**
     * Envia uma requisição com o método GET de forma assincrona
     *
     * @param url Url para a requisição. Obs: Ele já é automaticamente combinado com a url base
     */
    HttpAsyncService.prototype.get = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            var validationResult, headers;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.beforeValidations) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.beforeValidations()];
                    case 1:
                        validationResult = _a.sent();
                        if (validationResult.error !== undefined)
                            return [2 /*return*/, this.error(validationResult.error)];
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.getHeaders()];
                    case 3:
                        headers = _a.sent();
                        return [4 /*yield*/, this.http.get(this.baseUrl + url, headers).toPromise()
                                .then(function (data) {
                                return _this.success(data);
                            })
                                .catch(function (error) {
                                return _this.error(error);
                            })
                                .then(function (result) {
                                return result;
                            })];
                    case 4: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Envia uma requisição com o método POST
     *
     * @param url Url para a requisição. Obs: Ele já é automaticamente combinado com a url base
     * @param payload Informações a serem enviadas para o servidor
     */
    HttpAsyncService.prototype.post = function (url, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var validationResult, headers;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.beforeValidations) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.beforeValidations()];
                    case 1:
                        validationResult = _a.sent();
                        if (validationResult.error !== undefined)
                            return [2 /*return*/, this.error(validationResult.error)];
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.getHeaders()];
                    case 3:
                        headers = _a.sent();
                        return [4 /*yield*/, this.http.post(this.baseUrl + url, payload, headers).toPromise()
                                .then(function (data) {
                                return _this.success(data);
                            })
                                .catch(function (error) {
                                return _this.error(error);
                            })
                                .then(function (result) {
                                return result;
                            })];
                    case 4: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Envia uma requisição com o método PUT
     *
     * @param url Url para a requisição. Obs: Ele já é automaticamente combinado com a url base
     * @param payload Informações a serem enviadas para o servidor
     */
    HttpAsyncService.prototype.put = function (url, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var validationResult, headers;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.beforeValidations) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.beforeValidations()];
                    case 1:
                        validationResult = _a.sent();
                        if (validationResult.error !== undefined)
                            return [2 /*return*/, this.error(validationResult.error)];
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.getHeaders()];
                    case 3:
                        headers = _a.sent();
                        return [4 /*yield*/, this.http.put(this.baseUrl + url, payload, headers).toPromise()
                                .then(function (data) {
                                return _this.success(data);
                            })
                                .catch(function (error) {
                                return _this.error(error);
                            })
                                .then(function (result) {
                                return result;
                            })];
                    case 4: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Envia uma requisição com o método DELETE
     *
     * @param url Url para a requisição. Obs: Ele já é automaticamente combinado com a url base
     */
    HttpAsyncService.prototype.delete = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            var validationResult, headers;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.beforeValidations) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.beforeValidations()];
                    case 1:
                        validationResult = _a.sent();
                        if (validationResult.error !== undefined)
                            return [2 /*return*/, this.error(validationResult.error)];
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.getHeaders()];
                    case 3:
                        headers = _a.sent();
                        return [4 /*yield*/, this.http.delete(this.baseUrl + url, headers).toPromise()
                                .then(function (data) {
                                return _this.success(data);
                            })
                                .catch(function (error) {
                                return _this.error(error);
                            })
                                .then(function (result) {
                                return result;
                            })];
                    case 4: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    HttpAsyncService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpAsyncService_Factory() { return new HttpAsyncService(i0.ɵɵinject(i1.HttpClient)); }, token: HttpAsyncService, providedIn: "root" });
    return HttpAsyncService;
}());
export { HttpAsyncService };
