import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth.guard';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
var ɵ0 = { expectedRole: 'admin' };
var routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full', },
    {
        path: 'dashboard',
        component: AdminLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
            },
        ],
        canActivate: [AuthGuard],
        data: ɵ0,
    },
    {
        path: 'login',
        loadChildren: './pages/login/login.module#LoginModule',
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };
