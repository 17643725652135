//#region Imports
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Location } from '@angular/common';
import { ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Keys } from '../../utils/keys';
//#endregion
//#region Components
var NavbarComponent = /** @class */ (function () {
    //#region Constructor
    /**
     * Construtor padrão
     */
    function NavbarComponent(location, element, router) {
        this.location = location;
        this.element = element;
        this.router = router;
        //#endregion
        //#region Private Properties
        /**
         * Diz se o menu do mobile está visível
         */
        this.mobile_menu_visible = false;
        this.sidebarVisible = false;
    }
    //#endregion
    //#region LifeCycle Events
    /**
     * Método executado ao iniciar o componente
     */
    NavbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.listRoutes = Keys.ROUTES.filter(function (listTitle) { return listTitle; });
        var navbar = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this.router.events.subscribe(function () {
            _this.sidebarClose();
            var layer = document.getElementsByClassName('close-layer')[0];
            if (!layer)
                return;
            layer.remove();
            _this.mobile_menu_visible = false;
        });
    };
    //#endregion
    //#region Public Methods
    /**
     * Método que abre a sidebar
     */
    NavbarComponent.prototype.sidebarOpen = function () {
        var toggleButton = this.toggleButton;
        var body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');
        this.sidebarVisible = true;
    };
    /**
     * Método que fecha a sidebar
     */
    NavbarComponent.prototype.sidebarClose = function () {
        var body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    /**
     * Método que realiza o toggle da sidebar
     */
    NavbarComponent.prototype.sidebarToggle = function () {
        var _this = this;
        var toggle = document.getElementsByClassName('navbar-toggler')[0];
        if (this.sidebarVisible === false)
            this.sidebarOpen();
        else
            this.sidebarClose();
        var body = document.getElementsByTagName('body')[0];
        if (this.mobile_menu_visible) {
            body.classList.remove('nav-open');
            // layer && layer.remove();
            setTimeout(function () {
                toggle.classList.remove('toggled');
            }, 400);
            this.mobile_menu_visible = false;
            return;
        }
        setTimeout(function () {
            toggle.classList.add('toggled');
        }, 430);
        var layer = document.createElement('div');
        layer.setAttribute('class', 'close-layer');
        if (body.querySelectorAll('.main-panel'))
            document.getElementsByClassName('main-panel')[0].appendChild(layer);
        else if (body.classList.contains('off-canvas-sidebar'))
            document.getElementsByClassName('wrapper-full-page')[0].appendChild(layer);
        setTimeout(function () {
            layer.classList.add('visible');
        }, 100);
        layer.onclick = function () {
            body.classList.remove('nav-open');
            _this.mobile_menu_visible = false;
            layer.classList.remove('visible');
            setTimeout(function () {
                layer.remove();
                toggle.classList.remove('toggled');
            }, 400);
        };
        body.classList.add('nav-open');
        this.mobile_menu_visible = true;
    };
    /**
     * Método que retorna o titulo da página
     */
    NavbarComponent.prototype.getTitle = function () {
        var title = this.location.prepareExternalUrl(this.location.path());
        if (title.charAt(0) === '#')
            title = title.slice(1);
        for (var item = 0; item < this.listRoutes.length; item++) {
            if (this.listRoutes[item].path !== title)
                continue;
            return this.listRoutes[item].title;
        }
        return 'Dashboard';
    };
    /**
     * Método que realiza o logout do usuário
     */
    NavbarComponent.prototype.performLogout = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                localStorage.clear();
                this.router.navigateByUrl('/login');
                return [2 /*return*/];
            });
        });
    };
    return NavbarComponent;
}());
export { NavbarComponent };
